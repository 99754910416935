import "./App.css";
import { useState, useEffect } from "react";
import {QRCodeSVG} from 'qrcode.react';
const ecashaddrjs = require("ecashaddrjs");
const bs58check = require("bs58check");

function cashAddrToLegacy(cashaddr) {
  console.log("cashaddr", cashaddr);
  const {type, hash} = ecashaddrjs.decode(cashaddr);
  const typeBits = type === "P2PKH" ? 0 : 5
  const typeBuf = Buffer.alloc(1);
  typeBuf.writeInt8(typeBits);
  const buf = Buffer.concat([typeBuf, Buffer.from(hash)]);
  return bs58check.encode(buf);
}

function RevShareInput(props) {
  return (
    <div>
      <input className="nickname"
          type="text"
          value={props.nickname}
          placeholder="Nickname"
          onChange={(e) => props.handleRevShareChange(props.index, "nickname", e.target.value)}
        />
      <div className="revshare">
        <input className="address"
          type="text"
          value={props.address}
          placeholder="eToken Address"
          onChange={(e) => props.handleRevShareChange(props.index, "address", e.target.value)}
        />
        <input className="percentage"
          type="text"
          value={props.percentage}
          placeholder="%"
          onChange={(e) => props.handleRevShareChange(props.index, "percentage", e.target.value)}
        />
      </div>
    </div>
  );
}

function App() {
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [revShareArray, setRevShareArray] = useState([{
    address: "",
    percentage: ""
  }]);
  const [revShareInputs, setRevShareInputs] = useState(null)
  const [totalPercent, setTotalPercent] = useState(0);

  const shouldAddLine = (arr) => {
    const isEmptyLine = arr.find(obj => 
      (!obj.address || obj.address === "") || (!obj.percentage || obj.percentage === "")
    )
    if (isEmptyLine)
      return false;

    return true;
  }

  const handleRevShareChange = (index, key, value, rsArr) => {
    setMessage("");
    console.log("revShareArray", revShareArray);
    const revShareArr = rsArr || revShareArray;
    if (!revShareArr[index])
      revShareArr[index] = {};
    revShareArr[index][key] = key === "percentage" && value[value.length-1] !== "." ? Number(value) || "" : value;
    console.log("revShareArr", revShareArr);
    // Add or remove lines
    const totalPercent = revShareArr.reduce((acc, curr) => {
      const percentage = Number(curr.percentage) || 0
      return acc + percentage;
    }, 0);

    if (totalPercent < 100 && shouldAddLine(revShareArr)) {
      revShareArr.push({
        address: "",
        percentage: ""
      })
    } else if (totalPercent === 100) {
      // Remove unnecessary lines
      for(let i = revShareArr.length - 1; i >= 0; i--) {
        if (!revShareArr[i].percentage || revShareArr[i].percentage === "") {
          revShareArr.splice(i, 1);
        }
      }
    } else if (totalPercent > 100) {
      setMessage("Total revenue share cannot be greater than 100%");
    }

    setRevShareArray(revShareArr);
    setRevShareInputs(revShareArr.map((revObj, i) => {
      return (
        <RevShareInput
            key={i}
            index={i}
            percentage={revObj.percentage}
            address={revObj.address}
            nickname={revObj.nickname}
            handleRevShareChange={handleRevShareChange}
          />
      )
    }));
    setTotalPercent(totalPercent);
  }

  // Populate fields if url arguments passed
  useEffect(() => {
    
    const params = new URLSearchParams(window.location.search);
    setName(params.get('name') || "");
    setPrice(params.get('price') || "");
    const revShareQuery = params.get('revshare');
    // console.log(revShareQuery);

    if (revShareQuery) {
      try {
        const revShareArr = JSON.parse(revShareQuery);
        // console.log("revShareArr", revShareArr);
        // setRevShareArray(revShareArr);
        //console.log("revShareArray", revShareArray);
        handleRevShareChange(0, "percentage", revShareArr[0].percentage, revShareArr);
      } catch (err) {
        console.error(err)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildInvoicePayload = () => {
    return {
      token_id:"7e7dacd72dcdb14e00a03dd3aff47f019ed51a6f1f4e4f532ae50692f62bc4e5", 
      slp_outputs: revShareArray.map(revObj => {
        return {
          address: cashAddrToLegacy(revObj.address),
          amount: (price * (revObj.percentage / 100)).toFixed(4)
        };
      }), 
      currency: "ecash",
      memo: "Purchase: " + name
    };
  }

  const resultMessage = (prUrl) => {
    return (
      <div>Payment request created successfully:
        <br></br>
        Item Name: {name}
        <br></br>
        Amount: {price}
        <br></br>
        <a href={prUrl} rel="noopener noreferrer" target="_blank">Click Here To Pay</a>
        <br></br>
        <br></br>
        Or Scan:
        <br></br>
        <QRCodeSVG value={prUrl} />
      </div>
    )
  }

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await fetch("https://pay.badger.cash/create_invoice", {
        headers: {
          'Accept': "application/json, text/plain, */*",
          'Content-Type': "application/json;charset=utf-8"
        },
        method: "POST",
        body: JSON.stringify(buildInvoicePayload()),
      });
      let resJson = await res.json();
      if (res.status === 200) {
        // setName("");
        // setPrice("");
        setMessage(resultMessage(resJson.paymentUrl));
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="App">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={name}
          placeholder="Product/Item Name"
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          value={price}
          placeholder="Product/Item Price (in USD)"
          onChange={(e) => setPrice(e.target.value)}
        />

        {revShareInputs || (
          <RevShareInput
          index={0}
          handleRevShareChange={handleRevShareChange}
        />
        )}

        {totalPercent === 100 && (
          <button type="submit">Create</button>
        )}

        <div className="message">
          {message ? <p>{message}</p> : null}</div>
      </form>
    </div>
  );
}

export default App;
